import CartaCaracteracteristicas from "./CartaCaracteristicas";
import "./Style/styleCartaCaracteristicas.css";
import { useTranslation } from "react-i18next";
//Libreria de animación
import { motion } from "framer-motion";

export const Caracteristicas = () => {
  const { t } = useTranslation("Conocenos");
  const lista = [
    {
      Titulo: t("listaCaracteristicas.carta1.Titulo"),
      texto: t("listaCaracteristicas.carta1.Texto"),
      Negro: t("listaCaracteristicas.carta1.TextoNegrita"),
    },
    {
      Titulo: t("listaCaracteristicas.carta2.Titulo"),
      texto: t("listaCaracteristicas.carta2.Texto"),
      Negro: t("listaCaracteristicas.carta2.TextoNegrita"),
    },
    {
      Titulo: t("listaCaracteristicas.carta3.Titulo"),
      texto: t("listaCaracteristicas.carta3.texto"),
      Negro: t("listaCaracteristicas.carta3.TextoNegrita"),
    },
    {
      Titulo: t("listaCaracteristicas.carta4.Titulo"),
      texto: t("listaCaracteristicas.carta4.texto"),
      Negro: t("listaCaracteristicas.carta4.TextoNegrita"),
    },
    {
      Titulo: t("listaCaracteristicas.carta5.Titulo"),
      texto: (
        <p>
          {t("listaCaracteristicas.carta5.texto1")}
          <strong>{t("listaCaracteristicas.carta5.TextoNegrita")}</strong>{" "}
          {t("listaCaracteristicas.carta5.Texto2")}
        </p>
      ),
      Negro: "",
    },
    {
      Titulo: t("listaCaracteristicas.carta6.Titulo"),
      texto: t("listaCaracteristicas.carta6.Texto"),
      Negro: t("listaCaracteristicas.carta6.TextoNegrita"),
    },
  ];

  return (
    <div className="divSectionCaracteristicas">
      <h2 className="TituloCaracteristicasConocenos">
        {t("TituloCaracterisitca")}
      </h2>
      <div className="CaracteristicasConocenos">
        {lista.map((e) => {
          return (
            // div que va a tener la animacion se tiene que poner motion antes 
            <motion.div 
            //Estado inicial de la animacion 
              initial={{
                y: -90,
              }}
              //Animacion que hara cuando entre en vista 
              whileInView={{
                height: "auto",
                y: 0,
              }}
              //Efecto de transicion  que se aplicara 
              transition={{ duration: 0.5 }}
              //con la propiedad once especificamos que solo se repita una vez 
              viewport={{ once: true, amount: 0.8 }}
            >
              {" "}
              <CartaCaracteracteristicas
                titulo={e.Titulo}
                letraNegra={e.Negro}
                texto={e.texto}
              />
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};
