import { useTranslation } from "react-i18next";
import "./Style/styleTextoPrincipa.css";

export const TextoPrincipal = () => {
  const { t } = useTranslation("Conocenos");
  return (
    <div>
      <h2 className="titleSection1">STV</h2>
      <h2 className="titleSection2">Canarias SL</h2>
      <h3 className="subtitleSection">{t("subtitleSection")}</h3>
    </div>
  );
};
