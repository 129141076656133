export const sendEmailAPI = async (name, lastName, email, emailMessage, curriculum) => {

    const url = 'https://apiemail.mctplug.com?uri=sendEmail'

    const formData = new FormData()
    formData.append('name', name)
    formData.append('emailFrom', email)
    formData.append('emailTo', 'personal@fsv.es')
    formData.append('emailMessage', emailMessage)
    formData.append('curriculum', curriculum)

    const response = await fetch(url, {
        method: "POST",
        body: formData
    })

    const responseJSON = await response.json()

    return responseJSON

}