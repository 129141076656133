import { Image } from "react-bootstrap";
import { FiMail } from "react-icons/fi";
import "./style/styleCardPersonal2.css";
import { FaLinkedinIn } from "react-icons/fa";
import { Link } from "react-router-dom";
import { BsFillTelephoneFill } from "react-icons/bs";

export const CardPersonal2 = (props) => {
  return (
    <div className="cardBoxCarta">
      <div className="carta">
        <div className="front">
          <h3 className="TituloCartaRet">{props.trabajo}</h3>
          <img className="logo" src={require("./image/FSV.png")}></img>
          <img
            className="isla"
            src={require("./image/Islas_Logo/blue/" + props.isla)}
          ></img>
          <Image src={require(`./image/${props.retrato}`)}></Image>
        </div>
        <div className="back">
          <img
            className="isla"
            src={require("./image/Islas_Logo/white/" + props.isla)}
          ></img>
          <h3>{props.nombre}</h3>
          <p className="trabajoPersonal">{props.trabajo}</p>

          <div className="linksInformacionPersonal">
            <div>
              <div className="inIcon">
                <Link to={props.linkedin} target="_blank">
                  <FaLinkedinIn size={15} />
                </Link>
                <a href={"mailto:" + props.correo}>
                  <FiMail></FiMail>
                </a>
                <a href={"tel:" + props.telefono}>
                  <BsFillTelephoneFill></BsFillTelephoneFill>
                </a>
              </div>
            </div>
          </div>
          <img className="cartaQr" src={require("./image/" + props.qr)}></img>
        </div>
      </div>
    </div>
  );
};
