import Row from "react-bootstrap/Row";
import { RxDividerVertical } from "react-icons/rx";
const ListaHistoria = () => {


  return (
    <>
      <Row className="row-timeConon">
        <h2>1992</h2>
      </Row>
      <RxDividerVertical className="iconoFlecha" color="#006baa" />
      <Row className="row-timeConon">
        <h2>2014</h2>
      </Row>
      <RxDividerVertical className="iconoFlecha" color="#006baa" />
      <Row className="row-timeConon">
        <h2>2019</h2>
      </Row>
      <RxDividerVertical className="iconoFlecha" color="#006baa" />
      <Row className="row-timeConon">
        <h2>2023</h2>
      </Row>
    </>
  );
};
export default ListaHistoria;
