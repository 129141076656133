import { Container, Col, Row } from "react-bootstrap";
import { GiGreenPower } from "react-icons/gi";
import { GiCalculator } from "react-icons/gi";
import { AiOutlineScissor } from "react-icons/ai";
import { TbTrees } from "react-icons/tb";
import { BsPencilSquare } from "react-icons/bs";
import "./styleSectionGreen.css";
import { useTranslation } from "react-i18next";
const SectionGreen = () => {
  const { t } = useTranslation("Green");
  return (
    <Container style={{ minWidth: "100%" }}>
      <Row>
        <Col md={10} lg={11} className="divSectionGreen"></Col>
        <Col md={2} lg={1}>
          <GiGreenPower className="iconSizeTitle" color="green" />
        </Col>
      </Row>
      <Row>
        <Col lg={10}>
          <h1 className="titleSectionGreen">
            <strong>STV Green</strong>. {t("SectionTitle.Title")}
          </h1>
        </Col>
      </Row>
      <Row className="row-IconGreen">
        <Row className="row-shadow">
          <Row>
            <Col sm={3} md={3} lg={3} className="col-IconGreen">
              <GiCalculator className="iconSize" color="white" />
              <Row className="row-movilTitleText">
                <h1>{t("SectionIconos.Calcula.title")}</h1>
                <h2>{t("SectionIconos.Calcula.text")}</h2>
              </Row>
            </Col>
            <Col sm={3} md={3} lg={3} className="col-IconGreen">
              <BsPencilSquare className="iconSize" color="white" />
              <Row className="row-movilTitleText">
                <h1>{t("SectionIconos.Define.title")}</h1>
                <h2>{t("SectionIconos.Define.text")}</h2>
              </Row>
            </Col>
            <Col sm={3} md={3} lg={3} className="col-IconGreen">
              <AiOutlineScissor className="iconSize" color="white" />
              <Row className="row-movilTitleText">
                <h1>{t("SectionIconos.Reduce.title")}</h1>
                <h2>{t("SectionIconos.Reduce.text")}</h2>
              </Row>
            </Col>
            <Col sm={3} md={3} lg={3} className="col-IconGreen">
              <TbTrees className="iconSize" color="white" />
              <Row className="row-movilTitleText">
                <h1>{t("SectionIconos.Compensa.title")}</h1>
                <h2>{t("SectionIconos.Compensa.text")}</h2>
              </Row>
            </Col>
          </Row>
          <Row className="row-normalDiseño">
            <Row>
              <Col sm={3} md={3} lg={3} className="col-IconGreenTitle">
                <h1>{t("SectionIconos.Calcula.title")}</h1>
              </Col>
              <Col sm={3} md={3} lg={3} className="col-IconGreenTitle">
                <h1>{t("SectionIconos.Define.title")}</h1>
              </Col>
              <Col sm={3} md={3} lg={3} className="col-IconGreenTitle">
                <h1>{t("SectionIconos.Reduce.title")}</h1>
              </Col>
              <Col sm={3} md={3} lg={3} className="col-IconGreenTitle">
                <h1>{t("SectionIconos.Compensa.title")}</h1>
              </Col>
            </Row>
            <Row>
              <Col sm={3} md={3} lg={3} className="col-IconGreensubText">
                <h2>{t("SectionIconos.Calcula.text")}</h2>
              </Col>
              <Col sm={3} md={3} lg={3} className="col-IconGreensubText">
                <h2>{t("SectionIconos.Define.text")}</h2>
              </Col>
              <Col sm={3} md={3} lg={3} className="col-IconGreensubText">
                <h2>{t("SectionIconos.Reduce.text")}</h2>
              </Col>
              <Col sm={3} md={3} lg={3} className="col-IconGreensubText">
                <h2>{t("SectionIconos.Compensa.text")}</h2>
              </Col>
            </Row>
          </Row>
        </Row>
      </Row>
      <Row className="row-InfoGreen">
        <Row className="row-infoGreenTitle">
          <Col sm={3} md={3} lg={3} className="col-InfoGreen">
            <h2>{t("SectionInfo.col1.title")}</h2>
          </Col>
          <Col sm={3} md={3} lg={3} className="col-InfoGreen">
            <h2>{t("SectionInfo.col2.title")}</h2>
          </Col>
          <Col sm={3} md={3} lg={3} className="col-InfoGreen">
            <h2>{t("SectionInfo.col3.title")}</h2>
          </Col>
          <Col sm={3} md={3} lg={3} className="col-InfoGreen">
            <h2>{t("SectionInfo.col4.title")}</h2>
          </Col>
        </Row>
        <Row className="row-infoGreenText">
          <Col sm={3} md={3} lg={3} className="col-InfoGreenText">
            <p>{t("SectionInfo.col1.text")}</p>
          </Col>
          <Col sm={3} md={3} lg={3} className="col-InfoGreenText">
            <p>{t("SectionInfo.col2.text")}</p>
          </Col>
          <Col sm={3} md={3} lg={3} className="col-InfoGreenText">
            <p>{t("SectionInfo.col3.text")}</p>
          </Col>
          <Col sm={3} md={3} lg={3} className="col-InfoGreenText">
            <p>{t("SectionInfo.col4.text")}</p>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};
export default SectionGreen;
