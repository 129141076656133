import Card from "react-bootstrap/Card";
import "./style/styleCardIcono.css";
//Componente encargado de crear las cartas y de mostrar la información de las mismas. Contiene un boton para mostar más información.

const CardIcono = (props, index) => {
  return (
    <Card className="mi-tarjeta">
      <div className="divImagenIcono">
        <Card.Img
          variant="top"
          src={require(`./images/${props.imageName}`)}
          className="card-img-top-Portada"
        />
      </div>
      <Card.Body className="card-body-Portada">
        <p>{props.text}</p>
      </Card.Body>
    </Card>
  );
};

export default CardIcono;
