import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import "./config/in18" //Se importa la configuracion que hemos creado en el archivo 
                        //padre de todo el proyecto Siempre para evitar Errores

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import "/node_modules/flag-icons/css/flag-icons.min.css";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  

  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
