
import { Row } from "react-bootstrap";
import "./style/stylePersonal.css";
import { useTranslation } from "react-i18next";
import Formulario from "../Formulario/Formulario";

import { CardPersonal2 } from "./CardPersona2";
import uniqid from "uniqid";
const Personal = () => {

  const { t } = useTranslation("Personal");

  const gerentes = [
    {
      retrato: "Daniel.png",
      nombre: "Daniel Hernández",
      trabajo: t("Trabajo.PuestoDaniel"),
      linkedin:
        "https://www.linkedin.com/in/daniel-hern%C3%A1ndez-encinoso-a686a663",
      telefono: "+34 638 277 033 ",
      correo: "daniel.hernandez@fsvstv.onmicrosoft.com",
      isla: "TF.png",
      qr: "qrDaniel.png",
    },
    {
      retrato: "Carlos.png",
      nombre: "Carlos Díaz Encinoso",
      trabajo: t("Trabajo.PuestoCarlos"),
      linkedin: "https://es.linkedin.com/in/carlos-diaz-encinoso-8b66b517b",
      telefono: "+34 665 372 238",
      correo: "c.diaz@fsvstv.onmicrosoft.com",
      isla: "M.png",
      qr: "qrCarlos.png",
    },

    {
      retrato: "Christian.png",
      nombre: "Christian Martín González ",
      trabajo: t("Trabajo.PuestoChristian"),
      telefono: "+34 625 89 79 29",
      linkedin: "https://www.linkedin.com/in/christian-mart%C3%ADn-b2410170",
      correo: "christian.martin@fsvstv.onmicrosoft.com",
      isla: "TF.png",
      qr: "qrChristian.png",
    },
    {
      retrato: "Andres.png",
      nombre: "Andrés Rondón",
      trabajo: t("Trabajo.PuestoAndres"),
      telefono: "+34 635 114 915",
      correo: "andres.rondon@fsvstv.onmicrosoft.com",
      isla: "TF.png",
      qr: "qrAndres.png",
    },
    {
      retrato: "Foto1.png",
      nombre: "Mar Galera ",
      trabajo: t("Trabajo.PuestoMar"),
      telefono: "+34 626 177 793",
      linkedin: "https://www.linkedin.com/in/mariadelmargaleraciria",
      correo: "mar.galera@fsvstv.onmicrosoft.com",
      isla: "TF.png",
      qr: "qrMar.png",
    },
  ];



  return (
    <>
      <div
        className="fondoPersonal"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          minHeight: "100vh",
        }}
      >
        {/* FIla de titulo */}
        <Row style={{ width: "100%" }}>
          <div className="title">
            <h2 className="titleFirst">{t("Titulo1")}</h2>
            <h2 className="titleSecond">{t("Titulo2")}</h2>
          </div>
        </Row>
        <Row style={{ width: "100%" }}>
          <div className="lineaPersonal">
            {gerentes.map((gerente, index) => (
              

              <CardPersonal2
                key={uniqid()}
                linkedin={gerente.linkedin}
                retrato={gerente.retrato}
                nombre={gerente.nombre}
                trabajo={gerente.trabajo}
                telefono={gerente.telefono}
                correo={gerente.correo}
                isla={gerente.isla}
                qr={gerente.qr}
              ></CardPersonal2>
            ))}
          </div>
        </Row>
      </div>

      <Formulario />
    </>
  );
};

export default Personal;
