import { useEffect } from "react";
import { useParams } from "react-router-dom";
import uniqid from "uniqid";
import "./style/GeneralStyles.css";

//documentacion
import reglamentoArchipielago from "../../documentacion/fsvArchipielago/REGLAMENTO-FSV-Archipielago-.pdf";
//
import direccionGeneralArchipielago from "../../documentacion/fsvArchipielago/DPT-Director-General.pdf";
import direccionCOmerArchipielago from "../../documentacion/fsvArchipielago/DPT-Director-Comercial.pdf";
import direccionOperArchipielago from "../../documentacion/fsvArchipielago/DPT-Director-Operacione-.pdf";
import direccionRRHHArchipielago from "../../documentacion/fsvArchipielago/DPT-Responsable-RR.HH.pdf";
import direccionAdminiArchipielago from "../../documentacion/fsvArchipielago/DPT-Gerente-Administracion-revis-18-10-22.pdf";
//
import presupuesto2021Archipielago from "../../documentacion/fsvArchipielago/PTO-FSV-ARCHIPIELAGO-2021.pdf";
import presupuesto2022Archipielago from "../../documentacion/fsvArchipielago/PTO-FSV-ARCHIPIELAGO-2022.pdf";
import auditoriaArchipielago from "../../documentacion/fsvArchipielago/INFORME-AUDITORIA-FSV-ARCHIPIELAGO-2021.pdf";
import cuentasANualesArchipielago from "../../documentacion/fsvArchipielago/FSV-ARCHIPIELAGO_CCAA-2021-.pdf";
//
import subvencionesArchipielago from "../../documentacion/fsvArchipielago/FSV-Archipielago-.pdf";
//Documentacion Gran Canaria
import reglamentoGranCanaria from "../../documentacion/fsvGranCanaria/REGLAMENTO-FSV-Gran-Canaria- (1).pdf";
//
import direccionGeneralGranCanaria from "../../documentacion/fsvGranCanaria/DPT-Director-General (1).pdf";
import direccionOPeracionesGranCanaria from "../../documentacion/fsvGranCanaria/DPT-Director-Operacione- (1).pdf";
import direccionCormercialvaGranCanaria from "../../documentacion/fsvGranCanaria/DPT-Director-Comercial (1).pdf";
import direccionRecursosHGranCanaria from "../../documentacion/fsvGranCanaria/DPT-Responsable-RR.HH-revis-18-10-22.pdf";
import direccionAdministracionGranCanaria from "../../documentacion/fsvGranCanaria/DPT-Gerente-Administracion-revis-18-10-22 (1).pdf";
//
import presupuesto2022GranCanaria from "../../documentacion/fsvGranCanaria/PTO-FSV-GRANCANARIA-2022.pdf";
import presupuesto2021GranCanaria from "../../documentacion/fsvGranCanaria/PTO-FSV-GRAN-CANARIA-2021.pdf";
import cuentasAnualesGranCanaria from "../../documentacion/fsvGranCanaria/FSV-GRAN-CANARIA_CCAA_2021.-pdf.pdf";
import InformedeAuditoriaGranCanaria from "../../documentacion/fsvGranCanaria/INFORME-AUDITORIA-FSV-GRAN-CANARIA_-2021.pdf";
//
import subvencionesGranCanaria from "../../documentacion/fsvGranCanaria/FSV-Gran-Canaria.pdf";
//Documentacion Lanzarote
import reglamentoLanzarote from "../../documentacion/fsvLanzarote/REGLAMENTO-FSV-Lanzarote.-.pdf";
//
import direccionGeneralLanzarote from "../../documentacion/fsvLanzarote/DPT-Director-General (2).pdf";
import direccionOperacionesLanzarote from "../../documentacion/fsvLanzarote/DPT-Director-Operacione- (2).pdf";
import direccionComercialLanzarote from "../../documentacion/fsvLanzarote/DPT-Director-Comercial (2).pdf";
import direccionRRHHLanzarote from "../../documentacion/fsvLanzarote/DPT-Responsable-RR.HH-revis-18-10-22 (1).pdf";
import direccionAdministracionLanzarote from "../../documentacion/fsvLanzarote/DPT-Gerente-Administracion-revis-18-10-22 (2).pdf";
//

import presupuesto2022Lanzarote from "../../documentacion/fsvLanzarote/PTO-FSV-LANZAROTE-2022.pdf";
import presupuesto2021Lanzarote from "../../documentacion/fsvLanzarote/PTO-FSV-LANZAROTE-2021.pdf";
import CUentasAnualesLanzarote from "../../documentacion/fsvLanzarote/FSV-LANZAROTE_CCAA-2021.pdf";
import AuditoriaLanzarote from "../../documentacion/fsvLanzarote/INFORME-AUDITORIA-FSV-LANZAROTE-2021-.pdf";
//
import subvencionesLanzarote from "../../documentacion/fsvLanzarote/FSV-Lanzarote.pdf";
import { useTranslation } from "react-i18next";

const InformacionExtra = (props) => {

  const { t } = useTranslation("Trasnparencia");
  let { idInformacion } = useParams();

  const listaInformacion = [
    {
      Id: "Informacion-Otros-Canarias",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>{t("TerceraPagina.Informacion-Otros-Canarias.Titulo")}</h2>
          </div>
          <div className="bodyInfoTrans">
            <p>
              {t("TerceraPagina.Informacion-Otros-Canarias.PrimeraDescripcion")}
            </p>
            <p>
              {t("TerceraPagina.Informacion-Otros-Canarias.SegundaDescripcion")}
            </p>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Estrategica-Canarias",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>
              {t("TerceraPagina.Informacion-Estrategica-Canarias.Titulo")}
            </h2>
          </div>
          <div className="bodyInfoTrans">
            <p>
              {t(
                "TerceraPagina.Informacion-Estrategica-Canarias.PrimeraDescripcion"
              )}
            </p>
            <p>
              {t("TerceraPagina.Informacion-Estrategica-Canarias.Reglamento")} (
              <a href={reglamentoArchipielago}>STV Canario</a>){" "}
            </p>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Organizativa-Canarias",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>
              {t("TerceraPagina.Informacion-Organizativa-Canarias.Titulo")}
            </h2>
          </div>
          <div className="bodyInfoTrans">
            <h3>
              {t(
                "TerceraPagina.Informacion-Organizativa-Canarias.PrimerSubtitulo"
              )}
            </h3>
            <ul>
              <li>
                <a href="https://www.boe.es/eli/es/rdlg/2010/07/02/1/con">
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.PrimerEnlace"
                  )}
                </a>
              </li>
              <li>
                <a href="https://www.boe.es/eli/es-cn/l/2014/12/26/12">
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.SegundoEnlace"
                  )}
                </a>
              </li>
              <li>
                <a href="https://www.boe.es/eli/es/l/2014/11/27/27">
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.TercerEnlace"
                  )}
                </a>
              </li>
            </ul>
            <h3>
              {t(
                "TerceraPagina.Informacion-Organizativa-Canarias.SegundoSubtitulo"
              )}
            </h3>
            <p>
              <strong>
                {t(
                  "TerceraPagina.Informacion-Organizativa-Canarias.PrimeraDescripcionStrong"
                )}
              </strong>
            </p>
            <p>
              {t(
                "TerceraPagina.Informacion-Organizativa-Canarias.SegundaDescripcion"
              )}
            </p>
            <p>
              {t(
                "TerceraPagina.Informacion-Organizativa-Canarias.TerceraDescripcion"
              )}
            </p>
            <ul>
              <li>
                <a href={direccionGeneralArchipielago}>
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.DirecciónGeneral"
                  )}
                </a>
              </li>
              <li>
                <a href={direccionOperArchipielago}>
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.DirecciónOperaciones"
                  )}
                </a>
              </li>
              <li>
                <a href={direccionCOmerArchipielago}>
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.DirecciónComercial"
                  )}
                </a>
              </li>
              <li>
                <a href={direccionRRHHArchipielago}>
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.DirecciónRRHH"
                  )}
                </a>
              </li>
              <li>
                <a href={direccionAdminiArchipielago}>
                  {t(
                    "TerceraPagina.Informacion-Organizativa-Canarias.DirecciónAdministración"
                  )}
                </a>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Economica-Canarias",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>{t("TerceraPagina.Informacion-Economica-Canarias.Titulo")}</h2>
          </div>
          <div className="bodyInfoTrans">
            <ul>
              <li>
                <h2>2022</h2>
                <ul>
                  <li>
                    {" "}
                    <a href={presupuesto2022Archipielago}>
                      {t(
                        "TerceraPagina.Informacion-Economica-Canarias.PrimerEnlace"
                      )}
                    </a>{" "}
                  </li>
                </ul>
              </li>

              <li>
                <h2>2021</h2>
                <ul>
                  <li>
                    <a href={presupuesto2021Archipielago}>
                      {t(
                        "TerceraPagina.Informacion-Economica-Canarias.SegundoEnlace"
                      )}{" "}
                    </a>
                  </li>
                  <li>
                    <a href={cuentasANualesArchipielago}>
                      {t(
                        "TerceraPagina.Informacion-Economica-Canarias.TercerEnlace"
                      )}
                    </a>
                  </li>
                  <li>
                    <a href={auditoriaArchipielago}>
                      {t(
                        "TerceraPagina.Informacion-Economica-Canarias.CuartoEnlace"
                      )}{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      ),
    },

    {
      Id: "Informacion-Contratos-Canarias",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>{t("TerceraPagina.Informacion-Contratos-Canarias.Titulo")}</h2>
          </div>
          <div className="bodyInfoTrans">
            <h3>2022</h3>
            <h4>
              {t("TerceraPagina.Informacion-Contratos-Canarias.Subtitulo")}
            </h4>
            <p>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.PrimeraDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.SegundoSubtitulo"
              )}
            </h4>
            <p>
              {" "}
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.SegundaDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.TercerSubtitulo"
              )}
            </h4>
            <p>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.TerceraDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.CuartoSubtitulo"
              )}
            </h4>
            <p>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.CuartaDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.QuintoSUbTitulo"
              )}
            </h4>
            <p>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.QuintaDescripcion"
              )}
            </p>

            <h3>2021</h3>
            <h4>
              {t("TerceraPagina.Informacion-Contratos-Canarias.SextoSubtitulo")}
            </h4>
            <p>
              {" "}
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.SextaDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.SeptimoSubtitulo"
              )}
            </h4>
            <p>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.SeptimaDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.OctavoSubtitulo"
              )}
            </h4>
            <p>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.OctabaDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.NovenoSubtitulo"
              )}
            </h4>
            <p>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.NovenoDescripcion"
              )}
            </p>
            <h4>
              {t(
                "TerceraPagina.Informacion-Contratos-Canarias.DecimoSubtitulo"
              )}
            </h4>
            <p>
              <a href={subvencionesArchipielago}>
                {t(
                  "TerceraPagina.Informacion-Contratos-Canarias.DecimaDescriptcion"
                )}
              </a>
            </p>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Otros-Baleares",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>OTROS (Excelencia, Sostenibilidad, Calidad, Medioambiente…)</h2>
          </div>
          <div className="bodyInfoTrans">
            <p>
              FSV Gran Canaria asume para una gestión sobresaliente, el
              compromiso a nivel social, ambiental y económico; todo ello en
              coherencia con nuestra cultura organizativa y alineado con la
              estrategia de la sociedad.
            </p>
            <p>
              Contamos con Certificados de Calidad a nivel mundial que avalan
              nuestro servicio y forma de hacer.{" "}
            </p>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Estrategica-Baleares",
      Contenido: (
        <div className="bodyText">
          <div>
            <h2>Información Estrategica y Memorias de Actuación</h2>
          </div>
          <div className="bodyInfoTrans">
            <div>
              <p>
                Estos documentos recogen las políticas sobre transparencia de la
                entidad FSV Gran Canaria y sus compromisos con la publicación de
                la información ajustadas a la legislación básica de
                transparencia, acceso a la información pública y buen gobierno
                de la Jefatura del Estado. {" "}
              </p>
              <p>
                REGLAMENTO (<a href={reglamentoGranCanaria}>FSV Gran Canaria</a>
                ){" "}
              </p>
            </div>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Organizativa-Baleares",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>Información Organizativa</h2>
          </div>
          <div className="bodyInfoTrans">
            <h3>Normativa aplicable</h3>
            <ul>
              <li>
                <a href="https://www.boe.es/eli/es/rdlg/2010/07/02/1/con">
                  {" "}
                  RDL 1/2010, de 2 Julio, del texto refundido de la Ley de
                  Sociedades de Capital
                </a>
              </li>
              <li>
                <a href="https://www.boe.es/eli/es-cn/l/2014/12/26/12">
                  Ley 12/2014, de 26 de diciembre, de transparencia y de acceso
                  a la información pública
                </a>
              </li>
              <li>
                <a href="https://www.boe.es/eli/es/l/2014/11/27/27">
                  Ley 27/2014, de 27 de noviembre y en el Real Decreto 634/2015,
                  de 10 de julio, por el que se aprueba el Reglamento del
                  Impuesto sobre Sociedades.
                </a>
              </li>
            </ul>
            <h3>Organigrama</h3>
            <p>
              <strong>
                “Buscamos personas comprometidas con nuestros valores”
              </strong>
            </p>
            <p>
              FSV presenta y fomenta valores primordiales y esenciales para el
              correcto desarrollo del capital humano.
            </p>
            <p>
              No existen jerarquías verticales en su estricta palabra, todos
              somos compañeros y cada uno forma parte importante del proceso,
              hacemos un todo. Por eso cuando ganamos, lo hacemos en equipo.
            </p>
            <ul>
              <li>
                <a href={direccionGeneralGranCanaria}>Dirección General</a>
              </li>
              <li>
                <a href={direccionOPeracionesGranCanaria}>
                  Dirección Operaciones
                </a>
              </li>
              <li>
                <a href={direccionCormercialvaGranCanaria}>
                  Dirección Comercial
                </a>
              </li>
              <li>
                <a href={direccionRecursosHGranCanaria}>Dirección RRHH</a>
              </li>
              <li>
                <a href={direccionAdministracionGranCanaria}>
                  Dirección Administración
                </a>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Economica-Baleares",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>Información Económica-Financiera</h2>
          </div>
          <div className="bodyInfoTrans">
            <ul>
              <li>
                <h2>2022</h2>
                <ul>
                  <li>
                    {" "}
                    <a href={presupuesto2022GranCanaria}>
                      Presupuesto 2022
                    </a>{" "}
                  </li>
                </ul>
              </li>

              <li>
                <h2>2021</h2>
                <ul>
                  <li>
                    <a href={presupuesto2021GranCanaria}>Presupuesto 2021 </a>
                  </li>
                  <li>
                    <a href={cuentasAnualesGranCanaria}>Cuentas Anuales </a>
                  </li>
                  <li>
                    <a href={InformedeAuditoriaGranCanaria}>
                      Informes de Auditoria{" "}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Contratos-Baleares",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>Contratos, Convenios Y Encomiendas De Gestión</h2>
          </div>
          <div className="bodyInfoTrans">
            <h3>2022</h3>
            <h4> Contrato Formalizados Entidades Públicas. </h4>
            <p>
              En el año 2022 No se han Producido contratos con Entidades
              públicas
            </p>
            <h4>Realización Contratos Resueltos Entidades Públicas. </h4>
            <p>
              {" "}
              En año 2022 No se han producido Desistimientos ni resoluciones de
              contratos con entidades públicas
            </p>
            <h4>
              Información Trimestral Contrato Menores con Entidades Públicas.
            </h4>
            <p>
              En año 2022 No hay relación de contratos menores con entidades
              públicas
            </p>
            <h4>Convenios con Entidades Públicas</h4>
            <p>En año 2022 No hay convenios con entidades públicas </p>
            <h4>Subvenciones Públicas</h4>
            <p>En año 2022 No hay subvenciones</p>

            <h3>2021</h3>
            <h4>Contrato Formalizados Entidades Públicas. </h4>
            <p>
              {" "}
              En el año 2021 No se han Producido contratos con Entidades
              públicas
            </p>
            <h4>Realización Contratos Resueltos Entidades Públicas.</h4>
            <p>
              En año 2021 No se han producido Desistimientos ni resoluciones de
              contratos con entidades públicas
            </p>
            <h4>
              Información Trimestral Contrato Menores con Entidades Públicas.{" "}
            </h4>
            <p>
              En año 2021 No hay relación de contratos menores con entidades
              públicas
            </p>
            <h4>Convenios con Entidades Públicas.</h4>
            <p>En año 2021 No hay convenios con entidades públicas</p>
            <h4>Subvenciones Públicas</h4>
            <p>
              <a href={subvencionesGranCanaria}>
                Subvenciones FSV Gran Canaria
              </a>
            </p>
          </div>
        </div>
      ),
    },
    {
      Id: "Informacion-Otros-Baleares",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>OTROS (Excelencia, Sostenibilidad, Calidad, Medioambiente…)</h2>
          </div>
          <div className="bodyInfoTrans">
            <p>
              FSV Lanzarote asume para una gestión sobresaliente, el compromiso
              a nivel social, ambiental y económico; todo ello en coherencia con
              nuestra cultura organizativa y alineado con la estrategia de la
              sociedad.{" "}
            </p>
            <p>
              Contamos con Certificados de Calidad a nivel mundial que avalan
              nuestro servicio y forma de hacer.
            </p>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="BodyTransparencia">
      {listaInformacion.map((element) => {
        if (element.Id === idInformacion) {
          return <div key={uniqid()}>{element.Contenido}</div>;
        }
      })}
    </div>
  );
};

export default InformacionExtra;
