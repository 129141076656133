import { CircularProgressbar } from 'react-circular-progressbar';

//Componente que hace de medidor 
const Gauge = (props) => {
 
  return (

    <div style={{ width: `${props.size}px`, height: '50px' }}>
      <CircularProgressbar
        value={props.valor}
        //Ratio del medidor 
        circleRatio={0.5}
        //Anchura de medidor 
        strokeWidth={8}
        styles={{
          path: {
            
            stroke: "#008000",
            strokeLinecap: 'round',
            transformOrigin: 'center center',
            

          },
          trail: {
            // Color de la pista
            stroke: '#D2D4D2',
            // Si se utilizan esquinas redondeadas o planas en los extremos: se puede utilizar "butt" o "redondas".
            strokeLinecap: 'round',
            
            
            transformOrigin: 'center center',
          },
          root: {
            
            transform: "rotate(0.75turn)"
          },
         
        }}
        
        startAngle={0}
        endAngle={180}
      />
    </div>
  )


};

export default Gauge;
