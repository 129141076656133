import { Link } from "react-router-dom";
import "./style/CardIsla.css";

const CardIsla = ({ Id, Titulo, destino, Pag }) => {
  let cardClass;
  console.log(Pag);
  if (Id === 1 || Id === undefined) {
    cardClass = "cardTransparencia"; // Clase para el Id 1
  } else if (Id === 2) {
    cardClass = "cardTransparencia-2"; // Clase para el Id 2
  } // Agrega más condiciones según tus necesidades
  return (
    <Link to={destino} className="enlaceTransparencia">
      <div className={cardClass}>
        <img
          src={require("./images/FSV.png")}
          className={Pag === 2 ? "imgPag2" : "imgPag1"}
          alt="logo Fsv"
        />
        <h2 className={Pag === 2 ? "h2Pag2" : "h2pag1"}>{Titulo}</h2>
      </div>
    </Link>
  );
};

export default CardIsla;
