import React, { Component } from "react";
import { Container, Row } from "react-bootstrap";
import VideoContainer from "./VideoContainer";
import SectionPortada from "./SectionPortada";
import CarruselPortada from "./CarruselPortada";
import ContainerMaps from "../Maps/ContainerMaps";
import Marcas from "./Marcas";
import backgroundimage from "./images/fondoSectionEspejo.png";
const BodyPortada = () => {
  return (
    <>
      <CarruselPortada />
      <SectionPortada />
      <Row
        style={{
          backgroundImage: `url(${backgroundimage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Marcas />
        <ContainerMaps />
      </Row>
    </>
  );
};
export default BodyPortada;
