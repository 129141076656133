import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import backgroundImage from "./images/fondoSectionEspejo.png";
import "./style/styleSectionCola.css";
import { useTranslation } from "react-i18next";

function Marcas() {
  const { t } = useTranslation("Colaboracion");
  return (
    <Container
      className="containerMarcasGlobal"
      style={{
        minWidth: "100%",
        paddingTop: "2%",
        height: "min-content",
      }}
    >
      <Row className="fila">
        <Col className="colMarcas">
          <div className="contenedorMarcas">
            <div className="contenedorLogosMarcas">
              <div className="cajaLogosMarcas">
                <img
                  src={require(`./images/logoPortadaFSV.png`)}
                  alt="imagen logo FSV"
                  className="imgLogoFSV"
                ></img>
              </div>
              <div className="cajaLogosMarcas">
                <img
                  src={require(`./images/logoPortada1.png`)}
                  alt="imagen logo CocaCola"
                  className="imgLogoCocaCola"
                ></img>
              </div>
              <div className="cajaLogosMarcas">
                <img
                  src={require(`./images/logoPortada2.png`)}
                  alt="imagen logo Unilever"
                  className="imgLogoUniEuro"
                ></img>
              </div>
              <div className="cajaLogosMarcas">
                <img
                  src={require(`./images/logoPortada3.png`)}
                  alt="imagen logo AbInBev"
                  className="imgLogoAbi"
                ></img>
              </div>
            </div>
            <div className="circuloMarcas">
              <h1 className="tituloMarcas">{t("MarcasText")}</h1>
            </div>
          </div>
        </Col>

        <Col className="colColaboraciones">
          <div className="circuloColaboraciones">
            <h2 className="tituloColaboraciones">{t("ColaboracionText")}</h2>
          </div>
          <div className="contenedorColaboraciones">
            <div className="contenedorLogosColaboraciones">
              <div className="cajaLogosColaboraciones">
                <img
                  src={require(`./images/logoPortada6.png`)}
                  alt="imagen logo Gobierno"
                  className="imgLogoGobierno"
                ></img>
              </div>
              <div className="cajaLogosColaboracionesPalmas">
                <img
                  src={require(`./images/logoPortada5.png`)}
                  alt="imagen logo Universidad de Las Palmas"
                  className="imgLogoPalmas"
                ></img>
              </div>
              <div className="cajaLogosColaboraciones">
                <img
                  src={require(`./images/logoPortada4.png`)}
                  alt="imagen logo Universidad Europea"
                  className="imgLogoEuropea"
                ></img>
              </div>
              <div className="cajaLogosColaboraciones">
                <img
                  src={require(`./images/logoPortada7.png`)}
                  alt="imagen logo La Laguna"
                  className="imgLogoLaguna"
                ></img>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Marcas;
