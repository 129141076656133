import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import './styles/styleSectionCard.css'
import ValueInfo from "./ValueInfo";
import uniqid from 'uniqid';
import { useTranslation } from "react-i18next";


const SectionCard = () => {
  const {t}= useTranslation("Servicio");
  
    let listUp = [
        {
            imageName:"serviciotecnico.png",
            title:t("Tarjeta1.Title"),
            subtitle:t("Tarjeta1.Subtitle"),
            text:t("Tarjeta1.Text")
      
           },
          {
            imageName: "sistema.png",
            title:t("Tarjeta2.Title"),
            subtitle:t("Tarjeta2.Subtitle"),
            text:t("Tarjeta2.Text")
          }
    ];
    let listDown = [
        {
          imageName: "transporte.png",
          title:t("Tarjeta3.Title"),
          subtitle:t("Tarjeta3.Subtitle"),
          text:t("Tarjeta3.Text")
        },
        {
          imageName: "mantenimiento.png",
          title:t("Tarjeta4.Title"),
          subtitle:t("Tarjeta4.Subtitle"),
          text:t("Tarjeta4.Text")
        }
      ];
    
    return (
        <Container
      style={{minWidth: "100%", paddingTop: "3.5%", paddingBottom: "3.5%", display:"flex", flexDirection:"column", justifyContent:"center", alignContent:"center" }}
    >
      <Row>
        {listUp.map((value, index) => (
          
          <Col sm={12} md={6} key={uniqid()} style={{ marginBottom: "2.5%", display:"flex", justifyContent:"center", alignContent:"center" }}>
            <ValueInfo
              in={index}
              imageName={value.imageName}
              title={value.title}
              subTitle={value.subtitle}
              text={value.text}
            />
          </Col>
        ))}
      </Row>
      <Row>
        {listDown.map((value, index) => (
          <Col sm={12} md={6} key={uniqid()} style={{marginBottom: "2.5%",display:"flex", justifyContent:"center", alignContent:"center" }}>
            <ValueInfo
              id={index}
              imageName={value.imageName}
              title={value.title}
              subTitle={value.subtitle}
              text={value.text}
            />
          </Col>
        ))}
      </Row>
    </Container>
    )
}

export default SectionCard