import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Map from "./Map";
import "./styleContainerMap.css";

const ContainerMaps = () => {
  const [position, setPosition] = useState([
    28.32903287107811, -16.367087710458975,
  ]);
  const [islandSelect, setIslandSelect] = useState();
  const [renderMap, setRenderMap] = useState(true);
  const [googleMapsLoc, setGoogleMapsLoc] = useState(
    "https://www.google.com/maps/place/FSV+Archipi%C3%A9lago/@28.329024,-16.367088,16z/data=!4m6!3m5!1s0xc402d70452a704b:0xaa92aaa18322bf3c!8m2!3d28.3290242!4d-16.3670877!16s%2Fg%2F11pbj910y0?hl=es"
  );

  useEffect(() => {
    switch (islandSelect) {
      case "Tenerife":
        setPosition([28.32903287107811, -16.367087710458975]);
        setGoogleMapsLoc(
          "https://www.google.com/maps/place/FSV+Archipi%C3%A9lago/@28.329024,-16.367088,16z/data=!4m6!3m5!1s0xc402d70452a704b:0xaa92aaa18322bf3c!8m2!3d28.3290242!4d-16.3670877!16s%2Fg%2F11pbj910y0?hl=es"
        );
        setRenderMap(true);
        break;
      case "Gran Canaria":
        setPosition([27.92416535313897, -15.401957694624112]);
        setGoogleMapsLoc(
          "https://www.google.com/maps/place/FSV+Vending+Gran+Canaria/@27.9240681,-15.401957,16z/data=!4m6!3m5!1s0xc4098fb1cd67f45:0xa8906fed06682972!8m2!3d27.9241246!4d-15.4019575!16s%2Fg%2F11xfb7xzc?hl=es"
        );
        setRenderMap(true);
        break;
      case "Lanzarote":
        setPosition([28.97597854333149, -13.538459]);
        setGoogleMapsLoc(
          "https://www.google.com/maps/place/FSV+Lanzarote+Canarias+S.L./@28.975941,-13.538459,16z/data=!4m6!3m5!1s0xc462769b41542b9:0xcf5ac51af093248!8m2!3d28.9759408!4d-13.5384588!16s%2Fg%2F1tkl4ks2?hl=es"
        );
        setRenderMap(true);
        break;
      case "Fuerteventura":
        setPosition([28.51973570768917, -13.845911084656368]);
        setGoogleMapsLoc(
          "https://www.google.com/maps/place/F.S.V+Fuerteventura/@28.519698,-13.845954,16z/data=!4m6!3m5!1s0xc47c89f96f0f74b:0xbc1460548d2c6310!8m2!3d28.5196976!4d-13.8459536!16s%2Fg%2F11csbnqhsn?hl=es"
        );
        setRenderMap(true);
        break;
      case "Mallorca":
        setPosition([39.6220166, 2.7206012]);
        setGoogleMapsLoc(
          "https://www.google.es/maps/place/Catec+Servicios+Tecnicos+SL/@39.6220166,2.7206012,17z/data=!3m1!4b1!4m6!3m5!1s0x1297eb337cdfcc53:0x47ba980e91d9f7c0!8m2!3d39.6220166!4d2.7231761!16s%2Fg%2F11b8z7ygg8?hl=es&entry=ttu"
        );
        setRenderMap(true);
        break;
      case "Menorca":
        setPosition([39.8601281, 4.2571108]);
        setGoogleMapsLoc(
          "https://www.google.es/maps/place/Freongas+Menorca+SLU/@39.8601281,4.2571108,17z/data=!3m1!4b1!4m6!3m5!1s0x1295864cf2162a4f:0x35f3d47b4c7ae5b5!8m2!3d39.8601281!4d4.2596857!16s%2Fg%2F11dxdzs4qg?hl=es&entry=ttu"
        );
        setRenderMap(true);
        break;
      case "Ibiza":
        setPosition([38.9438638, 1.412872]);
        setGoogleMapsLoc(
          "https://www.google.es/maps/place/Catec+ibiza/@38.9438638,1.412872,17z/data=!3m1!4b1!4m6!3m5!1s0x1299471b6bec92ad:0x37544219bc1043f0!8m2!3d38.9438638!4d1.4154469!16s%2Fg%2F11cjvl6rgm?hl=es&entry=ttu"
        );
        setRenderMap(true);
        break;
      default:
        break;
    }
    setRenderMap(true);
  }, [islandSelect, renderMap]);

  return (
    <div className="containerMapAll">
      <div className="containerTitleImage">
        {/* Fila que aparece cuando está en un ordenador o tablet horizontal */}
        {/* Botones Baleares */}
        <Row className="filaPC">
          <Col className="styleColumn">
            <Button
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                fontWeight: "bold",
                color: "#285a9f",
              }}
              className="styleButton"
              onClick={() => [setRenderMap(false), setIslandSelect("Mallorca")]}
            >
              Mallorca
            </Button>
          </Col>
          <Col className="styleColumn">
            <Button
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                fontWeight: "bold",
                color: "#285a9f",
              }}
              className="styleButton"
              onClick={() => [setRenderMap(false), setIslandSelect("Menorca")]}
            >
              Menorca
            </Button>
          </Col>
          <Col className="styleColumn">
            <Button
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                fontWeight: "bold",
                color: "#285a9f",
              }}
              className="styleButton"
              onClick={() => [setRenderMap(false), setIslandSelect("Ibiza")]}
            >
              Ibiza
            </Button>
          </Col>
        </Row>

        {/* Fila que aparece cuando pasa a formato movil*/}

        <Row style={{ margin: 0 }}>
          <div className="containerImage">
              <img
                src={require(`./images/mapa-stv.png`)}
                alt="imagen mapa STV"
                className="img-fluid"
              ></img>
          </div>
        </Row>
      </div>
      <div className="containerTitleImage">
        {/* Fila que aparece cuando está en un ordenador o tablet horizontal */}

        {/* Botones Canarias */}
        <Row className="filaPC">
          <Col className="styleColumn">
            <Button
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                fontWeight: "bold",
                color: "#285a9f",
              }}
              className="styleButton"
              onClick={() => [setRenderMap(false), setIslandSelect("Tenerife")]}
            >
              Tenerife
            </Button>
          </Col>
          <Col className="styleColumn">
            <Button
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                fontWeight: "bold",
                color: "#285a9f",
              }}
              className="styleButton"
              onClick={() => [
                setRenderMap(false),
                setIslandSelect("Gran Canaria"),
              ]}
            >
              Gran Canaria
            </Button>
          </Col>
          <Col className="styleColumn">
            <Button
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                fontWeight: "bold",
                color: "#285a9f",
              }}
              className="styleButton"
              onClick={() => [
                setRenderMap(false),
                setIslandSelect("Fuerteventura"),
              ]}
            >
              Fuerteventura
            </Button>
          </Col>
          <Col className="styleColumn">
            <Button
              style={{
                backgroundColor: "#ffffff",
                borderColor: "#ffffff",
                fontWeight: "bold",
                color: "#285a9f",
              }}
              className="styleButton"
              onClick={() => [
                setRenderMap(false),
                setIslandSelect("Lanzarote"),
              ]}
            >
              Lanzarote
            </Button>
          </Col>
        </Row>

        {/* Fila que aparece cuando pasa a formato movil*/}

        <Row style={{ margin: 0 }}>
          <div className="containerMap">
            {renderMap && (
              <Map googleMapsLoc={googleMapsLoc} position={position} />
            )}
          </div>
        </Row>
      </div>
    </div>
  );
};

export default ContainerMaps;
