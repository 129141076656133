import React from "react";
import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "./style/styleFooter.css";
import { Link } from "react-router-dom";
import { GrMail } from "react-icons/gr";
import { GoLocation } from "react-icons/go";
import { BsLinkedin, BsFillTelephoneFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import avisoLegal from "./data/avisoLegal.pdf";
import politicaCalidad from "./data/politicaCalidad.pdf";
import politicaPrivacidad from "./data/politicaPrivacidad.pdf";


export default function Footer() {
  const { t } = useTranslation("Footer");
  return (
    <div className="footerCapa">
      <MDBFooter className="footerContainer">
        <section>
          <MDBContainer className="text-center text-md-start mt-5">
            <MDBRow className="mt-3">
              <MDBCol
                md="2"
                lg="2"
                xl="2"
                className="mx-auto mb-4"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src={require(`./images/STVlogoBlanco.png`)}
                  alt="imagen logo FSV"
                  className="logoFooter"
                ></img>
              </MDBCol>
              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-md-0 mb-4">
                <h6 className="titulosFooter">{t("Contactanos")}</h6>

                <Link className="footerLinks" to="tel: 922 736 116">
                  <BsFillTelephoneFill size={20} />
                  <p className="informationFooter">922 736 116</p>
                </Link>

                <Link
                  className="footerLinks"
                  to="mailto:logistica@stvcanarias.com"
                >
                  <GrMail size={20} />
                  <p className="informationFooter">stvcanarias.com</p>
                </Link>
              </MDBCol>
              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="titulosFooter">Canarias</h6>
                <Link
                  className="footerLinks"
                  target="_blank"
                  to="https://www.google.es/maps/place/FSV+Archipi%C3%A9lago/@28.3324826,-16.3751651,16z/data=!4m10!1m2!2m1!1sManzana+F,+Parcela+15,+Pol%C3%ADgono+Industrial+de+G%C3%BCimar!3m6!1s0xc402d70452a704b:0xaa92aaa18322bf3c!8m2!3d28.3290242!4d-16.3670877!15sCjZNYW56YW5hIEYsIFBhcmNlbGEgMTUsIFBvbMOtZ29ubyBJbmR1c3RyaWFsIGRlIEfDvGltYXKSARh2ZW5kaW5nX21hY2hpbmVfc3VwcGxpZXLgAQA!16s%2Fg%2F11pbj910y0?hl=es"
                >
                  <GoLocation size={20} />
                  <p className="informationFooter">Tenerife</p>
                </Link>
                <Link
                  className="footerLinks"
                  target="_blank"
                  to="https://www.google.es/maps/search/C%2F+Isora,+s%2Fn+(esquina+c%2F+Mocanes)+P.I.+Las+Majoreras/@27.9200334,-15.4064282,1609m/data=!3m2!1e3!4b1?hl=es"
                >
                  <GoLocation size={20} />
                  <p className="informationFooter">Gran Canaria</p>
                </Link>
                <Link
                  className="footerLinks"
                  target="_blank"
                  to="https://www.google.es/maps/search/C%2F+Oc%C3%A9ano+%C3%8Dndico,+S%2FN+(P.I.+Arrecife+Puerto)/@28.9639878,-13.5589305,3186m/data=!3m2!1e3!4b1?hl=es"
                >
                  <GoLocation size={20} />
                  <p className="informationFooter">Lanzarote</p>
                </Link>
                <Link
                  className="footerLinks"
                  target="_blank"
                  to="https://www.google.es/maps/search/pi+la+hondura/@28.5175736,-13.8500473,3199m/data=!3m2!1e3!4b1?hl=es"
                >
                  <GoLocation size={20} />
                  <p className="informationFooter">Fuerteventura</p>
                </Link>
              </MDBCol>
              <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="titulosFooter">Baleares</h6>
                <Link
                  className="footerLinks"
                  target="_blank"
                  to="https://www.google.com/maps?q=catec+mallorca&um=1&ie=UTF-8&sa=X&ved=2ahUKEwj0r52J-cb-AhVYOcAKHdCVBaEQ_AUoAnoECAEQBA"
                >
                  <GoLocation size={20} />
                  <p className="informationFooter">Mallorca</p>
                </Link>
                <Link
                  className="footerLinks"
                  target="_blank"
                  to="https://www.google.com/maps/place/Catec+ibiza/@38.9438638,1.412872,17z/data=!3m1!4b1!4m6!3m5!1s0x1299471b6bec92ad:0x37544219bc1043f0!8m2!3d38.9438638!4d1.4154469!16s%2Fg%2F11cjvl6rgm"
                >
                  <GoLocation size={20} />
                  <p className="informationFooter">Ibiza</p>
                </Link>
                <Link className="footerLinks" target="_blank" to="https://www.google.com/maps/place/Freongas+Menorca+SLU/@39.8601281,4.2571108,17z/data=!3m1!4b1!4m6!3m5!1s0x1295864cf2162a4f:0x35f3d47b4c7ae5b5!8m2!3d39.8601281!4d4.2596857!16s%2Fg%2F11dxdzs4qg">
                  <GoLocation size={20} />
                  <p className="informationFooter">Menorca</p>
                </Link>
              </MDBCol>

              <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
                <h6 className="titulosFooter">{t("RS")}</h6>
                <Link
                  className="footerLinks"
                  target="_blank"
                  to="https://www.linkedin.com/company/stvending-canarias/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=es"
                >
                  <BsLinkedin size={20} />
                  <p className="informationFooter">STV Vending</p>
                </Link>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
        <div
          className="text-center p-4"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.05)", color: "white" }}
        >
          © 2023 Copyright:{" "}
          <span className="text-reset fw-bold">STV Canarias S.L.</span> |
          <Link className="linksPoliticas" target="_blank" to={avisoLegal}>
            {" "}
            {t("AVL")}
          </Link>{" "}
          |
          <Link className="linksPoliticas" target="_blank" to={politicaCalidad}>
            {" "}
            {t("PC")}
          </Link>{" "}
          |
          <Link className="linksPoliticas" target="_blank" to={politicaPrivacidad}>
            {" "}
            {t("PP")}
          </Link>
        </div>
      </MDBFooter>
    </div>
  );
}
