import { Col, Container } from "react-bootstrap";
import CardIcono from "./CardIcono";
import uniqid from "uniqid";
import "./style/styleCardIcono.css";
import { useTranslation } from "react-i18next";
const SectionIconos = () => {
  const { t } = useTranslation("Portada");
  const iconos = [
    {
      imgName: "IconoLaboral.png",
      text: t("Icono1.text"),
    },
    {
      imgName: "IconoLogistica.png",
      text: t("Icono2.text"),
    },
    {
      imgName: "IconoOrientacion.png",
      text: t("Icono3.text"),
    },
    {
      imgName: "IconoDesEquipo.png",
      text: t("Icono4.text"),
    },
  ];

  return (
    <>
      <div className="divTitleIconos">
        <p className="iconsTestTitle">{t("TituloPortada")}</p>
      </div>
      {iconos.map((value, index) => (
        <Col key={uniqid()} xs={6} sm={6} md={6} lg={3} className="colIcono">
          <CardIcono id={index} imageName={value.imgName} text={value.text} />
        </Col>
      ))}
    </>
  );
};
export default SectionIconos;
