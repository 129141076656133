import { Container, Row } from "react-bootstrap";
import Certificados from "./Certificados";
import SectionIconos from "./SectionIconos";
import TextSectionPortada from "./TextSectionPortada";
import backgroundImage from "./images/fondoSection.png";
import Marcas from "./Marcas";

const SectionPortada = () => {
  return (
    <div>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          minWidth: "100%",
          paddingTop: "5%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          margin: "0 !important",
          padding: "0 !important",
        }}
      >
        <Row
          sm={12}
          md={12}
          lg={12}
          xs={12}
          style={{ paddingLeft: "5%", paddingRight: "5%" }}
        >
          <SectionIconos />
        </Row>
        <Row sm={12} md={12} lg={12} xs={12}>
          <TextSectionPortada />
        </Row>
        <Row sm={12} md={12} lg={12} xs={12}>
          <Certificados />
        </Row>
      </div>
    </div>
  );
};
export default SectionPortada;
