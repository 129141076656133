import './styleFormularioTextSection.css'
import { GrMail } from 'react-icons/gr'
import { GoLocation } from 'react-icons/go'
import { BsFillTelephoneFill } from 'react-icons/bs'
import { useTranslation } from 'react-i18next';


const FormularioTextSection = () => {
    const {t}=useTranslation("Formulario");
    return (
        <div className="containerTextSection">
            <div className="titleFormContainer">
                <h1 className='titleFirst'>{t("titleFirst")}</h1>
                <h1 className='titleSecond'>{t("titleSecond")}</h1>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginLeft: "5%" }}>
                    <p style={{ textAlign: "center", fontWeight: "bold" }}>{t("text")}</p>
                </div>
                <div className='iconsContainer'>
                    <div className='singleIconContainer'>
                        <GoLocation size={20} />
                        <a href='https://www.google.es/maps/place/FSV+Archipi%C3%A9lago/@28.3324826,-16.3751651,16z/data=!4m10!1m2!2m1!1sManzana+F,+Parcela+15,+Pol%C3%ADgono+Industrial+de+G%C3%BCimar!3m6!1s0xc402d70452a704b:0xaa92aaa18322bf3c!8m2!3d28.3290242!4d-16.3670877!15sCjZNYW56YW5hIEYsIFBhcmNlbGEgMTUsIFBvbMOtZ29ubyBJbmR1c3RyaWFsIGRlIEfDvGltYXKSARh2ZW5kaW5nX21hY2hpbmVfc3VwcGxpZXLgAQA!16s%2Fg%2F11pbj910y0?hl=es' className='links'>Manzana F, Parcela 15, Polígono Industrial de Güimar, Tenerife</a>
                    </div>
                    <div className='singleIconContainer'>
                        <BsFillTelephoneFill size={20} />
                        <a href='tel: 922 736 116' className='links'>922 736 116</a>
                    </div>
                    <div className='singleIconContainer'>
                        <GrMail size={20} />
                        <a href='mailto:logistica@stvcanarias.com' className='links'>logistica@stvcanarias.com</a>
                    </div>
                </div>
                <div style={{ marginLeft: "5%" }}>
                    <h5 style={{ fontWeight: "bold" }}>{t("Information.Title")}</h5>
                    <p className='test'><strong>{t("Information.ResponsableTitle")}</strong> {t("Information.ResponsableText")}</p>
                    <p className='test'><strong>{t("Information.FinalidadTitle")}</strong> {t("Information.FinalidadText")} </p>
                    <p className='test'><strong>{t("Information.LegitimacionTitle")}</strong>{t("Information.LegitimacionText")}</p>
                    <p className='test'><strong>{t("Information.DerechosTitle")}</strong> {t("Information.DerechosText")}</p>
                    <p className='test'><strong>{t("Information.InformacionAdicionalTitle")}</strong>{t("Information.InformacionAdicionalText")}</p>
                </div>
            </div>

        </div>
    )
}

export default FormularioTextSection