function CardHistoria({ title, text }) {
  return (
    <>
      <h6>{title}</h6>
      <h4>{text}</h4>
    </>
  );
}

export default CardHistoria;
