import "./App.css";
import BodyPortada from "./Components/Portada/BodyPortada";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HeaderBar from "./Components/Header/HeaderBar";
import Navbar from "./Components/Header/Navbar";
import { Suspense } from "react";
import Conocenos from "./Components/Conocenos/Conocenos"
import Formulario from "./Components/Formulario/Formulario";
import Servicio from "./Components/Servicio/Servicio";
import Transparencia from "./Components/Transparencia/Transparencia";
import InformacionTransparencia from "./Components/Transparencia/InformacionTransparencia";
import InformacionExtra from "./Components/Transparencia/InformacionExtra";
import Personal from "./Components/NuestroEquipo/Personal";
import Footer from "./Components/Footer/Footer";
function App() {
  return (
    <Suspense> {/*Se debe  usa esta estiqueta si se usa en la configuracion el backend, para en caso de que esta no cargue o tarde se muestre un cargando */}
      <Router>
        <header>
          <HeaderBar />
          <Navbar />
        </header>
        <main>
          <Routes>
            <Route path="/" element={<BodyPortada />} />
            <Route path="/Conocenos" element={<Conocenos />} />
            <Route path="/Formulario" element={<Formulario />} />
            <Route path="/Servicio" element={<Servicio></Servicio>} />
            <Route path="/Transparencia" element={<Transparencia></Transparencia>} />
            <Route path="/transparencia/:idTransparencia" element={<InformacionTransparencia />}></Route>
            <Route path="/Informacion/:idInformacion" element={<InformacionExtra />}></Route>
            <Route path="/Equipo" element={<Personal></Personal>}></Route>
          </Routes>
          <footer>
            <Footer />
          </footer>
        </main>
      </Router>

    </Suspense>

  );
}

export default App;
