import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "../../App.css";

function Map(props) {
  const { googleMapsLoc, position } = props;

  return (
    <MapContainer
      style={{
        height: "45vh",
        border: "2px solid black",
      }}
      center={position}
      zoom={17}
      scrollWheelZoom={true}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <Marker position={[position[0], position[1]]}>
        <Popup>
          <a href={googleMapsLoc} target="_blank">
            Como llegar
          </a>
        </Popup>
      </Marker>
    </MapContainer>
  );
}

export default Map;
