import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styleNavbar.css";
import { useTranslation } from "react-i18next";



import { useEffect, useState } from "react";
const Navbar = () => {
  const {t,i18n}=useTranslation("Navbar")
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  function changeLanguageSpanish(){//funcion para cambiar el idioma a español
    i18n.changeLanguage("es")
        

  }
  function changeLanguageEnglish(){//funcion para cambiar el idioma a ingles
    i18n.changeLanguage("en")
    
  }
  function changeLanguageCatalan(){//funcion para cambiar el idioma a ingles
    i18n.changeLanguage("ca")
   
  }
  const plegarMenu = () => {
    let checkbox = document.getElementById("menu").checked;
    if (checkbox) {
      document.getElementById("menu").checked = false;
    } else {
      document.getElementById("menu").checked = true;
    }
    };

    useEffect(() => {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);
      };
  
  
  
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);
    const conoceno = windowWidth < 600
    ? <li>{t("Conocenos")}<ul className="menu2">
      <Link to={"/conocenos"} onClick={() => { plegarMenu() }}><li>{t("QuienesSomos")}</li></Link>
      <Link to={"/equipo"} onClick={() => { plegarMenu() }}><li>{t("NuestroEquipo")}</li></Link></ul></li>
    : <li><Link to={"/conocenos"} onClick={() => { plegarMenu() }}  >{t("Conocenos")}</Link><ul className="menu2"><Link to={"/conocenos"} onClick={() => { plegarMenu() }}><li>{t("QuienesSomos")}</li></Link><Link to={"/equipo"} onClick={() => { plegarMenu() }}><li>{t("NuestroEquipo")}</li></Link></ul></li>
  return (
    <nav>
      <div className="BotonesIdiomasMoviles">
          <button onClick={changeLanguageSpanish} className="fi fi-es "></button>
          <button onClick={changeLanguageEnglish} className="fi fi-gb"></button>
          <button onClick={changeLanguageCatalan} className="fi fi-es-ct"></button>
      </div>
      <input type="checkbox" id="menu" />
      <label htmlFor="menu"> ☰ </label>
      <ul id="menu1">
      <li style={{marginRight:"30%",width:"350px"}} className="BotonesIdiomas">

          <button onClick={changeLanguageSpanish} className="fi fi-es "></button>
          <button onClick={changeLanguageEnglish} className="fi fi-gb"></button>
          <button onClick={changeLanguageCatalan} className="fi fi-es-ct"></button>

          </li>
        {/* {test && (
          <li className="liBotonAtras">
            <BotonAtras />
          </li>
        )} */}
        <li>
          <Link
            to={"/"}
            onClick={() => {
              plegarMenu();
            }}
          >
            {t("Inicio")}
          </Link>
        </li>
       {
        conoceno
       }

        <li>
          <Link
            to={"/Servicio"}
            onClick={() => {
              plegarMenu();
            }}
          >
            {t("Servicios")}
          </Link>
        </li>
        <li>
          <Link
            to={"/transparencia"}
            onClick={() => {
              plegarMenu();
            }}
          >
            {t("Transparencia")}
          </Link>
        </li>

      </ul>
    </nav>
  );
};
export default Navbar;
