import { useTranslation } from 'react-i18next';
import './styles/styleSectionDetail.css'

const SectionDetail = () => {
    const {t}= useTranslation("Servicio");
    return(
        <div style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", width:"80%"}}>
            <div className="title">
                    <h1 className="titleFirst">{t("TitleFirts")}</h1>
                    <h1 className="titleSecond">{t("TitleSecond")}</h1>
                </div>
            <div style={{ textAlign:"justify"}}>
                <p className="textDescription">{t("TextDescription")}</p>
            </div>
        </div>
    )
}

export default SectionDetail