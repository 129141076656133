import React, { useState } from "react";
import './styles/styleValueInfo.css'
import Card from "react-bootstrap/Card";
import { RiArrowRightSLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";


//Componente encargado de crear las cartas y de mostrar la información de las mismas. Contiene un boton para mostar más información.

const ValueInfo = (props) => {
    const [show, setShow] = useState(false);
    const {t}=useTranslation("Servicio")
    
    return (
        <Card style={{ height: show ? "max-content" : "22rem", maxWidth: "20rem", minWidth: "100%", transition: "0.7s" }} >
            <Card.Img
                variant="top"
                src={require(`./images/${props.imageName}`)}
                style={{maxHeight:"10rem", maxWidth: "100%", objectFit: "cover" }}
            />
            <Card.Body>
                <p className="card-title">{props.title}</p>
                {/* <Card.Title className="card-title">{props.title}</Card.Title> */}
                <Card.Subtitle className="mb-2 text-muted">
                    {props.subTitle}
                </Card.Subtitle>
                <button className="btnCard" onClick={() => setShow(!show)}>
                    {t("leerMas")} <RiArrowRightSLine className={show ? 'rotateDownArrow' : 'rotateUpArrow'} size={50} />
                </button>
                {show && <Card.Text className="justifyText">{props.text}</Card.Text>}

            </Card.Body>
        </Card>
    );
};

export default ValueInfo;
