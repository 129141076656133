import React from "react";
import { Link } from "react-router-dom";
import "./styleHeaderBar.css";
import { GrMail } from "react-icons/gr";
import { GoLocation } from "react-icons/go";
import { BsLinkedin, BsFillTelephoneFill } from "react-icons/bs";

const HeaderBar = () => {
  return (
    <div>
      <div className="headerComponent">
        <div className="informationIcon">
          <Link
            className="colorItem"
            target="_blank"
            to="https://www.google.es/maps/place/FSV+Archipi%C3%A9lago/@28.3324826,-16.3751651,16z/data=!4m10!1m2!2m1!1sManzana+F,+Parcela+15,+Pol%C3%ADgono+Industrial+de+G%C3%BCimar!3m6!1s0xc402d70452a704b:0xaa92aaa18322bf3c!8m2!3d28.3290242!4d-16.3670877!15sCjZNYW56YW5hIEYsIFBhcmNlbGEgMTUsIFBvbMOtZ29ubyBJbmR1c3RyaWFsIGRlIEfDvGltYXKSARh2ZW5kaW5nX21hY2hpbmVfc3VwcGxpZXLgAQA!16s%2Fg%2F11pbj910y0?hl=es"
          >
            <GoLocation size={20} />
            <p className="information-Header">
              Manzana F, Parcela 15, Polígono Industrial de Güimar, Tenerife
            </p>
          </Link>

          <Link className="colorItem" to="tel: 922 736 116">
            <BsFillTelephoneFill size={20} />
            <p className="information-Header">922 736 116</p>
          </Link>

          <Link className="colorItem" to="mailto:logistica@stvcanarias.com">
            <GrMail size={20} />
            <p className="information-Header">logistica@stvcanarias.com</p>
          </Link>
        </div>
        <div className="socialIcon">
          <Link
            target="_blank"
            to="https://www.linkedin.com/company/stvending-canarias/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=es"
          >
            <BsLinkedin className="socialIconItems" size={25} />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeaderBar;
