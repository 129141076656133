import { Container, Col, Row } from "react-bootstrap";
import "./style/styleCertificiados.css";
import certificado9001 from "./data/9001.pdf";
import certificado14001 from "./data/14001.pdf";
import certificado22000 from "./data/22000.pdf";
import certificado45001 from "./data/45001.pdf";
import "./style/styleTextSectionPortada.css";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
const Certificados = () => {


  return (
    <Container>
      <motion.div
        className="divSectionCertificados"
        initial={{ scale: 0 }}
        whileInView={{ scale: 1 }}
        transition={{ duration: 4 }}
        viewport={{ once: true, amount: 0.8 }}
      >
        <Col sm={6} lg={2} xs={6} md={6} className="divCertificados">
          <a href={certificado9001} target="_blank" rel="noreferrer">
            <img
              src={require("./images/certi1.png")}
              alt="img certificados"
              className="imgCerti"
            ></img>
          </a>
        </Col>
        <Col sm={6} lg={2} xs={6} md={6} className="divCertificados">
          <a href={certificado14001} target="_blank" rel="noreferrer">
            <img
              src={require("./images/certi2.png")}
              alt="img certificados"
              className="imgCerti"
            ></img>
          </a>
        </Col>
        <Col sm={6} lg={2} xs={6} md={6} className="divCertificados">
          <a href={certificado22000} target="_blank" rel="noreferrer">
            <img
              src={require("./images/certi3.png")}
              alt="img certificados"
              className="imgCerti"
            ></img>
          </a>
        </Col>
        <Col sm={6} lg={2} xs={6} md={6} className="divCertificados">
          <a href={certificado45001} target="_blank" rel="noreferrer">
            <img
              src={require("./images/certi5.png")}
              alt="img certificados"
              className="imgCerti"
            ></img>
          </a>
        </Col>
        <Col sm={12} lg={2} xs={12} md={12} className="divCertificados">
          <img
            src={require("./images/certi4.png")}
            alt="img certificados"
            className="imgCerti"
          ></img>
        </Col>
      </motion.div>
    </Container>
  );
};
export default Certificados;
