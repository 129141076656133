import { Form, Button, Spinner } from "react-bootstrap";
import PoliticaPrivacidad from "./assets/POLITICA_DE_PRIVACIDAD-23-09-2021.pdf";
import { useState } from "react";
import { sendEmailAPI } from "../../js/api";

const FormularioSection = () => {
  const [name, setName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [message, setMessage] = useState();
  const [curriculum, setCurriculum] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [emailStatus, setEmailStatus] = useState(false);
  const [textEmailStatus, setTextEmailStatus] = useState("");

  const politica = (
    <p>
      He leído y acepto la{" "}
      <a
        href={PoliticaPrivacidad}
        target="_blank"
        rel="noreferrer"
        style={{ color: "#FFFFFF", fontWeight: "bold" }}
      >
        Política de Privacidad
      </a>
    </p>
  );

  const checkExtension = (fileName) => {
    const extension = fileName.split(".").pop();

    if (extension === "pdf") {
      return true;
    } else {
      return false;
    }
  };

  const enviarMail = async () => {
    if (isChecked) {
      if (checkExtension(curriculum.name)) {
        setIsUploading(true);
        const response = await sendEmailAPI(
          name,
          lastName,
          email,
          message,
          curriculum
        );

        if (response.result) {
          setEmailStatus(true);
          setTextEmailStatus("Se ha envíado con éxito");
        } else {
          setTextEmailStatus(
            "Ha ocurrido un error. Inténtelo de nuevo mas tarde"
          );
        }

        setIsUploading(false);
      }
    }
  };

  return (
    <div
      style={{
        width: "90%",
        height: "min-content",
        backgroundColor: "#285a9f",
        padding: "40px 20px 40px 20px",
        borderRadius: "5px",
        marginBottom: "10px",
      }}
    >
      <Form>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ color: "white", display: "block" }}>
            Nombre*
          </Form.Label>
          <Form.Control
            required
            type="text"
            name="nombre"
            placeholder="Introduce tu nombre"
            onChange={(text) => setName(text.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ color: "white", display: "block" }}>
            Apellidos*
          </Form.Label>
          <Form.Control
            required
            type="text"
            name="apellidos"
            placeholder="Introduce tus apellidos"
            onChange={(text) => setLastName(text.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label style={{ color: "white", display: "block" }}>
            Email*
          </Form.Label>
          <Form.Control
            required
            type="email"
            name="email"
            placeholder="Introduce tu dirección de correo"
            onChange={(text) => setEmail(text.target.value)}
          />
        </Form.Group>

        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
          <Form.Label style={{ color: "white", display: "block" }}>
            Mensaje
          </Form.Label>
          <Form.Control
            onChange={(text) => setMessage(text.target.value)}
            as="textarea"
            name="mensaje"
            rows={3}
          />
        </Form.Group>

        <Form.Group controlId="formFileMultiple" className="mb-3">
          <Form.Label style={{ color: "white", display: "block" }}>
            Currículum (PDF)*
          </Form.Label>
          <Form.Control
            required
            type="file"
            multiple
            name="curriculum"
            onChange={(e) => setCurriculum(e.target.files[0])}
          />
        </Form.Group>

        <Form.Group
          className="mb-3"
          controlId="formBasicCheckbox"
          style={{ color: "white" }}
        >
          <Form.Check
            required
            type="checkbox"
            style={{ display: "block" }}
            label={politica}
            name="politicas"
            onClick={(e) => setIsChecked(e.target.checked)}
          />
        </Form.Group>
        <Form.Group>
          <Button onClick={() => enviarMail()} variant="light">
            {isUploading ? (
              <Spinner size="sm" style={{ color: "#EEB927" }} />
            ) : (
              "Enviar"
            )}
          </Button>
          {emailStatus && (
            <Form.Label style={{ color: "white", display: "block" }}>
              {textEmailStatus}
            </Form.Label>
          )}
        </Form.Group>
      </Form>
    </div>
  );
};

export default FormularioSection;