import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { TextoPrincipal } from "./TextoPrincipal";
import { useTranslation } from "react-i18next";
import "./Style/styleConocenos.css";
import { CardConocenos } from "./CardConocenos";
import uniqid from "uniqid";
import { Caracteristicas } from "./Caracteristicas";
import { ListaMedidores } from "./ListaMedidores";
import SectionGreen from "./Green/SectionGreen";
import { AiFillVideoCamera } from "react-icons/ai";
import { BsGearWide } from "react-icons/bs";
import { GiPadlock } from "react-icons/gi";
import { FaEnvira } from "react-icons/fa";
import CardHistoria from "./CardHistoria";
import ListaHistoria from "./listaHistoria";

export const Conocenos = () => {
  const { t } = useTranslation("Conocenos"); //funcion que permite traducir

  const listRight = [
    {
      imageName: t("Tarjeta1.ImageName"),
      title: t("Tarjeta1.Title"),
      subtitle: t("Tarjeta1.Subtitle"),
      text: t("Tarjeta1.Text"),
    },
  ];
  const infoCardHistorias = [
    {
      title: t("ListaHistoria.Historia1.titulo"),
      text: t("ListaHistoria.Historia1.texto"),
      year: "1992",
    },
    {
      title: t("ListaHistoria.Historia2.titulo"),
      text: t("ListaHistoria.Historia2.texto"),
      year: "2014",
    },
    {
      title: t("ListaHistoria.Historia3.titulo"),
      text: t("ListaHistoria.Historia3.texto"),
      year: "2019",
    },
    {
      title: t("ListaHistoria.Historia4.titulo"),
      text: t("ListaHistoria.Historia4.texto"),
      year: "2023",
    },
  ];
  return (
    <div className="Conocenos">
      <div className="FondoGeneral">
        <Row className="SectionHistoria">
          <Col xs={12} sm={5} md={5} lg={4}>
            <Row>
              <TextoPrincipal className="ConocenosContainer" />
            </Row>
            <Row className="row-divCarta">
              {listRight.map((e) => {
                return (
                  <CardConocenos
                    className="cartaConocenos"
                    key={uniqid()}
                    imageName={e.imageName}
                    text={e.text}
                    title={e.title}
                    subTitle={e.subtitle}
                  ></CardConocenos>
                );
              })}
            </Row>
          </Col>
          <Col sm={7} md={7} lg={8}>
            <Row>
              <Col sm={3} md={3} lg={3} className="col-iconosConocenos">
                <Row className="row-iconosConocenos">
                  <BsGearWide className="iconSizeHis" color="gray" />
                </Row>
                <Row className="row-iconosConocenos">
                  <AiFillVideoCamera className="iconSizeHis" color="gray" />
                </Row>
                <Row className="row-iconosConocenos">
                  <GiPadlock className="iconSizeHis" color="gray" />
                </Row>
                <Row className="row-iconosConocenos">
                  <FaEnvira className="iconSizeHis" color="gray" />
                </Row>
              </Col>
              <Col sm={5} md={5} lg={5} className="col-infoHisConocenos">
                {infoCardHistorias.map((e) => {
                  return (
                    <Row>
                      <div className="displayCono">
                        <Row id="timeCono1" className="row-timeConon">
                          <h2>{e.year}</h2>
                        </Row>
                      </div>

                      <Row className="row-cardHistoria">
                        <CardHistoria
                          key={uniqid()}
                          title={e.title}
                          text={e.text}
                        ></CardHistoria>
                      </Row>
                    </Row>
                  );
                })}
              </Col>
              <Col sm={4} md={4} lg={4} className="col-timeConon">
                <ListaHistoria />
              </Col>
            </Row>
          </Col>
        </Row>
        <Caracteristicas />
      </div>
      <ListaMedidores />
      <SectionGreen />
    </div>
  );
};
export default Conocenos;
