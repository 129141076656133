import "./style/styleTextSectionPortada.css";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { useState, useEffect } from "react";

const TextSectionPortada = () => {
  const { t } = useTranslation("Portada");

  return (
    <motion.div
      className="divTextCompleto"
      animate={{
        scale: [0, 1],
      }}
      initial="offscreen"
      whileInView="onscreen"
      transition={{
        duration: 2,
        loop: Infinity, // hace que la animación se repita infinitamente
        repeatDelay: 1, // opcional, tiempo de espera entre repeticiones
        times: [0, 1],
      }}
    >
      <div className="divText1Portada">
        <h2>{t("TextPortada")}</h2>
      </div>
      <div className="divText2Portada">
        <h3>{t("TextPortada2")}</h3>
      </div>
    </motion.div>
  );
};
export default TextSectionPortada;
