//Libreria de traduccion
import i18n from "i18next";

import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend"
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(Backend) //en esta linea especificamos que vamos a usar los json que estan en la carpeta public 
    .use(LanguageDetector) //Este use especifica que se usara la deteccion com`patible de lenguaje junto con el cambio manual
    .use(initReactI18next).init({
        detection: { order: [ "navigator"] },//especificacion del orden de deteccion del lenguaje en este caso solo el navegador se puede añadir mas de un detector el que va primero es el prioritario
        fallbackLng: "es" //idioma por defecto en caso de no detectar traducion

    }
)

export default i18n