import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import ImgPortada1 from "./images/ImgPortada1.png";
import ImgPortada2 from "./images/ImgPortada2.png";
import ImgPortada3 from "./images/ImgPortada3.png";
import ImgPortada4 from "./images/ImgPortada4.png";
import ImgPortada5 from "./images/ImgPortada5.png";


import { useTranslation } from "react-i18next";

import "./style/styleCarruselPortada.css";

const handleDragStart = (e) => e.preventDefault();
const items = [
  <img
    src={ImgPortada3}
    onDragStart={handleDragStart}
    role="presentation"
    className="imgPortada"
  />,
  <img
    src={ImgPortada2}
    onDragStart={handleDragStart}
    role="presentation"
    className="imgPortada"
  />,
  <img
    src={ImgPortada1}
    onDragStart={handleDragStart}
    role="presentation"
    className="imgPortada"
  />,
  <img
    src={ImgPortada4}
    onDragStart={handleDragStart}
    role="presentation"
    className="imgPortada"
  />,
  <img
    src={ImgPortada5}
    onDragStart={handleDragStart}
    role="presentation"
    className="imgPortada"
  />,
];
const CarruselPortada = () => {
  
  const {t} = useTranslation('Carrusel')

  
  
  return (
    <div className="sectionCarruselPortada">
      <AliceCarousel
        items={items}
        autoPlay
        autoPlayInterval={4500}
        infinite
        disableButtonsControls
        disableDotsControls
      />
      <div className="divImagenLogo">
        <h2 className="titlePortada">
          
          {t('Titulo1')} <span >{t("VideoTitle2")}</span>
        </h2>
      </div>
    </div>
  );
};
export default CarruselPortada;
