import React, { useState } from "react";
import "./Style/styleCard.css";
import Card from "react-bootstrap/Card";
import { RiArrowRightSLine } from "react-icons/ri";
import { useTranslation } from "react-i18next";

export const CardConocenos = (props) => {
  const { t } = useTranslation("Conocenos");
  //Constante para mostrar o no el texto 
  const [show, setShow] = useState(false);
  return (
    <Card className="cartaConocenos">
      <Card.Img
        variant="top"
        src={require(`./images/${props.imageName}`)}
        className="imgCardConocenos"
      />
      <Card.Body>
        <Card.Title className="card-title-Cono">{props.title}</Card.Title>
        <Card.Subtitle className="card-subtitle-Cono">
          {props.subTitle}
        </Card.Subtitle>
        {/* Dependiendo del estado de la constante se le pondra un clasname u otro a la flecha y esta girara  */}
        <button className="btnCard" onClick={() => setShow(!show)}>
          {t("leerMas")}{" "}
          <RiArrowRightSLine
            className={show ? "rotateDownArrow" : "rotateUpArrow"}
            size={50}
          />
        </button>
        {/* Dependiendo del estado de la constante show esta mostrara o no el texto */}
        {show && (
          <Card.Text className="card-text-justifyText textoCartaConocenos" >{props.text}</Card.Text>
        )}
      </Card.Body>
    </Card>
  );
};
