import { Container, Row, Col } from "react-bootstrap";
import BackgroundImage from "./images/fondoServicio.png";
import SectionCard from "./SectionCard";
import SectionDetail from "./SectionDetail";
import './styles/styleServicio.css'


const Servicio = () => {

  return (
    <Container
    style={{
      minWidth: "100%",
      minHeight: "100vh",
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      
    }}>
      <Row>
        <Col className="columna" md={12} lg={4}>
          <SectionDetail />
        </Col>
        <Col md={12} lg={7}>
          <SectionCard />
        </Col>
      </Row>
    </Container>
  )
}

export default Servicio