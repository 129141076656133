
import "./Style/styleCartaCaracteristicas.css"
const CartaCaracteristicas =({titulo,texto,letraNegra})=>{
   
    return(
        <div className="ConocenosCartaCaracteristicas">
            <h2>{titulo}</h2>
            <p>{texto} 
            
            <strong>{letraNegra}</strong>
            </p>
            
        </div>
    )
}
export default CartaCaracteristicas