import Gauge from "./Gauge"
import { FaEnvira } from 'react-icons/fa';
import { HiOutlineUserGroup } from 'react-icons/hi';
import { FaBalanceScale } from 'react-icons/fa';
import { GiBreakingChain } from 'react-icons/gi';
import "./Style/styleListaMedidores.css"
import { useTranslation } from 'react-i18next';




export const ListaMedidores = () => {
    const {t}=useTranslation("Conocenos")
    const lista = [
        {

            titulo: t("Medidores.Medidor1.Titulo"),
            valor: 59,
            size: 180
        },
        {
            icon: <FaEnvira size={25}/>,
            titulo: t("Medidores.Medidor2.Titulo"),
            valor: 60,
            size: 100
        },
        {
            icon: <HiOutlineUserGroup size={25}/>,
            titulo: t("Medidores.Medidor3.Titulo"),
            valor: 60,
            size: 100
        },
        {
            icon: <FaBalanceScale size={25}/>,
            titulo: t("Medidores.Medidor4.Titulo"),
            valor: 60,
            size: 100
        },
        {
            icon: <GiBreakingChain size={25}/>,
            titulo: t("Medidores.Medidor5.Titulo"),
            valor: 50,
            size: 100
        }
    ]
    
    return (
        <div className="containerListaMedidores" >
            {lista.map((item) => {
                return (
                    <div className="containerMedidor">
                        <div className="iconMedidor">{item.icon}</div>
                        <p className="tituloMedidor">{item.titulo}</p>
                        <div className="containerGauge">
                            {/* Titulo del valor de los medidores */}
                            <Gauge size={item.size} valor={item.valor} />  
                            <p className="valorGauge"><span>{item.valor}</span>/100</p>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}