import { useEffect } from "react";
import { Row, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import uniqid from "uniqid";
import CardIsla from "./CardIsla";
import { Link } from "react-router-dom";
//Rutas a los diferentes archivos
import estatutosArcipielago from "../../documentacion/fsvArchipielago/ESTATUTOS-ARCHIPIELAGO-.pdf";
import estatutosGranCanaria from "../../documentacion/fsvGranCanaria/ESTATUTOS-GRAN-CANARIA.pdf";
import estatutosLanzarote from "../../documentacion/fsvLanzarote/ESTATUTOS-LANZAROTE.pdf";
import "./style/GeneralStyles.css";
import { useTranslation } from "react-i18next";

const InformacionTransparencia = (props) => {
  // const { setTest } = props
  const {t}=useTranslation("Trasnparencia")
  let { idTransparencia } = useParams();
  // useEffect(() => {
  //     setTest(true)
  // }, [setTest])

  const listaInformacion = [
    {
      Id: "Stv-Canarias",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>{t("SegundaPagina.Stv-Canarias.Titulo")}</h2>
          </div>
          <div className="divInfoArch">
            <p>
              <Link to={"/"}>STV </Link>{t("SegundaPagina.Stv-Canarias.PrimeraDescripcion")}
            </p>

            <p>
            
              {t("SegundaPagina.Stv-Canarias.SegundaDescripcion")}
              <a href={estatutosArcipielago}>{t("SegundaPagina.Stv-Canarias.Estatutos")}</a>
            </p>
            <p>
              {t("SegundaPagina.Stv-Canarias.TerceraDescripcion")}
              <strong>{t("SegundaPagina.Stv-Canarias.TerceraDescripcionStrong")}</strong>
              {t("SegundaPagina.Stv-Canarias.TerceraDescripcionFinal")}
              <a href="https://www.boe.es/eli/es/l/2013/12/09/19/con">
              {t("SegundaPagina.Stv-Canarias.TerceraDescripcionEnlace")}
              </a>
              {t("SegundaPagina.Stv-Canarias.TerceraDescripcionDespuesEnlace")}
            </p>
            <p>
            {t("SegundaPagina.Stv-Canarias.CuartaDescripcion")}
              <a href="https://www.boe.es/eli/es-cn/l/2014/12/26/12">
              {t("SegundaPagina.Stv-Canarias.CuartaDescripcionEnlace")}
              </a>
              {t("SegundaPagina.Stv-Canarias.CuartaDescripcionDespuesENlace")}
              <strong>
              {t("SegundaPagina.Stv-Canarias.CuartaDescripcionStrong")}
              </strong>
              {t("SegundaPagina.Stv-Canarias.CUartaDescripcionDespuesStrong")}
              <strong>
              {t("SegundaPagina.Stv-Canarias.CuartaDescripcionStrongDos")}
              </strong>
              {t("SegundaPagina.Stv-Canarias.CuartaDescripcionStrongDosDespues")}
            </p>
            <p>
            {t("SegundaPagina.Stv-Canarias.QuintaDescripcion")}
              <strong>{t("SegundaPagina.Stv-Canarias.QuintaDescripcionStrong")}</strong>
              {t("SegundaPagina.Stv-Canarias.QuintaDescripcionStrongDespues")}
              <a href="https://transparenciacanarias.org/">
              {t("SegundaPagina.Stv-Canarias.QuintaDescripcionEnlace")}
              </a>
              {t("SegundaPagina.Stv-Canarias.QuintaDescripcionPunto")}
            </p>
            <p>
            {t("SegundaPagina.Stv-Canarias.SextaDescripcion")}
            </p>
            <p>
            {t("SegundaPagina.Stv-Canarias.SeptimaDescripcion")}
            </p>
            <p>
            {t("SegundaPagina.Stv-Canarias.OctavaDescripcion")}
            </p>
          </div>
        </div>
      ),
    },
    {
      Id: "Stv-Baleares",
      Contenido: (
        <div className="bodyText">
          <div className="pageTitle">
            <h2>Información Institucional</h2>
          </div>

          <p>
            <Link to={"/"}>FSV Gran Canaria</Link> ofrece desde 1992 en Canarias
            un
            <strong>
              {" "}
              servicio integral de vending, con máquinas de última generación y
              tecnología punta, además de una amplia variedad de productos de
              marcas premium en el mercado.
            </strong>
          </p>
          <p>
            Como empresa de Vending, está regulada por los{" "}
            <strong>Estatutos correspondientes</strong> (normas que regula el
            funcionamiento de esta). Puedes consultarla en el siguiente enlace:{" "}
            <a href={estatutosGranCanaria}>Estatutos FSV Gran Canaria</a>
          </p>
          <p>
            Uno de los{" "}
            <strong>valores principales de FSV es la transparencia</strong>, es
            por ello por lo que publicamos diversa información obtenida en
            diferentes ámbitos. Además, dando cumplimiento a la{" "}
            <a href="https://www.boe.es/eli/es/l/2013/12/09/19/con">
              Ley estatal 19/2013, de 9 de diciembre
            </a>
            , de transparencia
          </p>
          <p>
            También se atiende a la{" "}
            <a href="https://www.boe.es/eli/es-cn/l/2014/12/26/12">
              Ley canaria 12/2014, de 26 de diciembre
            </a>
            , de{" "}
            <strong>transparencia y de acceso a la información pública </strong>
            , la cual nos afecta según su artículo 3, por ser una entidad
            privada óque percibe ayudas o subvenciones, en una cuantía superior
            a 60.000 euros, con cargo a los
            <strong> Presupuestos de la Comunidad Autónoma de Canarias</strong>,
            para la financiación de sus actividades y funcionamiento ordinario.
          </p>
          <p>
            Este compromiso con la transparencia está definido en nuestra{" "}
            <strong>Política de Transparencia</strong>, actualizada y ratificada
            contando con la evaluación externa del{" "}
            <a href="https://transparenciacanarias.org/">
              Comisionado de Transparencia de Canarias.
            </a>
          </p>
          <p>
            Las personas asociadas a la entidad pueden solicitar
            <strong>información de forma presencial</strong> en la sede Polígono
            Industrial Las Majoreras, Calle Los Mocanes 2, Ingenio, Las Palmas
            de Gran Canaria.
          </p>
          <p>
            También realizamos{" "}
            <strong>
              {" "}
              auditorías de gestión de calidad y auditoría de cuentas
            </strong>{" "}
            que garantizan la debida diligencia de las personas trabajadoras y
            la credibilidad de la información.
          </p>
          <p>
            Siguiendo las recomendaciones del Comisionado de Transparencia de
            Canarias, hemos dividido la información en varias categorías; en los
            siguientes enlaces se puede consultar la información que publicamos:
          </p>
        </div>
      ),
    },
  ];
  const listaEnlacesInformacion = [
    {
      Pag: 2,
      Titulo: "Información Estrategica y Memorias de actuación",
      Destino: "/Informacion/Informacion-Estrategica-Canarias",
      Padre: "Stv-Canarias",
    },
    {
      Pag: 2,
      Titulo: "Información Organizativa",
      Destino: "/Informacion/Informacion-Organizativa-Canarias",
      Padre: "Stv-Canarias",
    },
    {
      Pag: 2,
      Titulo: "Información Económica-Financiera",
      Destino: "/Informacion/Informacion-Economica-Canarias",
      Padre: "Stv-Canarias",
    },
    {
      Pag: 2,
      Titulo:
        "Contratos, Convenios, Encomiendas de gestión y subvenciones públicas",
      Destino: "/Informacion/Informacion-Contratos-Canarias",
      Padre: "Stv-Canarias",
    },
    {
      Pag: 2,
      Titulo: "Otros( Excelencia, Sostenibilidad, etc...) ",
      Destino: "/Informacion/Informacion-Otros-Canarias",
      Padre: "Stv-Canarias",
    },
    {
      Titulo: "Información Estrategica y Memorias de actuación",
      Destino: "/Informacion/Informacion-Estrategica-Baleares",
      Padre: "Stv-Baleares",
    },
    {
      Titulo: "Información Organizativa",
      Destino: "/Informacion/Informacion-Organizativa-Baleares",
      Padre: "Stv-Baleares",
    },
    {
      Titulo: "Información Económica-Financiera",
      Destino: "/Informacion/Informacion-Economica-Baleares",
      Padre: "Stv-Baleares",
    },
    {
      Titulo:
        "Contratos, Convenios, Encomiendas de gestión y subvenciones públicas",
      Destino: "/Informacion/Informacion-Contratos-Baleares",
      Padre: "Stv-Baleares",
    },
    {
      Titulo: "Otros( Excelencia, Sostenibilidad, etc...) ",
      Destino: "/Informacion/Informacion-Otros-Baleares",
      Padre: "Stv-Baleares",
    },
  ];

  return (
    <div className="BodyTransparencia">
      {listaInformacion.map((element) => {
        if (element.Id === idTransparencia) {
          return <div key={uniqid()}>{element.Contenido}</div>;
        }
      })}
      {listaEnlacesInformacion.map((element) => {
        if (element.Padre === idTransparencia) {
          return (
            <Container key={uniqid()}>
              <Row>
                {" "}
                <CardIsla
                  Titulo={element.Titulo}
                  destino={element.Destino}
                  Pag={element.Pag}
                />
              </Row>
            </Container>
          );
        }
      })}
    </div>
  );
};
export default InformacionTransparencia;
