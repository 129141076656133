import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardIsla from "./CardIsla";
import uniqid from "uniqid";
import "./style/GeneralStyles.css";

//import "./style/styleBotonAtras.css";

import { useTranslation } from "react-i18next";

const Transparencia = (props) => {
  // const { setTest } = props;
  const {t}=useTranslation("Trasnparencia")
  const listaEnlacesTrasnparencia = [
    {
      Id: 1,
      Titulo: "STV CANARIAS SL",
      Destino: "/transparencia/Stv-Canarias",
    },
    {
      Id: 2,
      Titulo: "STV BALEARES SL",
      // Destino: "/transparencia/Stv-Baleares",
    },
  ];

  // useEffect(() => {
  //   setTest(false);
  // }, [setTest]);

  return (
    <div className="BodyTransparencia">
      <div className="pageTitle">
        <h2>{t("TituloPrincipal")}</h2>
      </div>

      <Container className="Principal">
        <Row className="FilaTrasnparencia">
          <Col sm={12} xs={12} md={12} lg={5}>
            <h3>
             {t("PrimeraDescripcion")}
            </h3>
            <h3>
            {t("SegundaDescripcion")}
            </h3>
          </Col>
          <Col md={1}></Col>
          <Col
            xs={12}
            sm={12}
            md={12}
            lg={5}
            className="ColumnCardTrasnparencia"
          >
            {listaEnlacesTrasnparencia.map((element) => {
              return (
                <CardIsla
                  key={uniqid()}
                  Id={element.Id}
                  Titulo={element.Titulo}
                  destino={element.Destino}
                />
              );
            })}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
export default Transparencia;
