import { Container, Row, Col} from "react-bootstrap";
import BackgroundImage from "./images/fondoSectionEspejo.png";
import FormularioSection from "./FormularioSection";
import FormularioTextSection from "./FormularioTextSection";
function Formulario() {
    

    
    return (
        <Container
            style={{
                minWidth: "100%",
                minHeight: "100vh",
                backgroundImage: `url(${BackgroundImage})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",

            }}>
            <Row>
                <Col md={12} lg={6} style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                    <FormularioTextSection/>
                </Col>
                <Col md={12} lg={6} style={{minHeight:"100vh", display:"flex", alignItems:"center", justifyContent:"center"}}>
                   <FormularioSection/>
                </Col>
            </Row>
        </Container>


    );
}

export default Formulario;